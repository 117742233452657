import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Chip, CircularProgress, Divider, Stack, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "./global/store/SnackbarStore";
import PageHeader from "./global/components/PageHeader";
import {useUserStore} from "./global/store/UserStore";
import {checkAuth, logoutFromSystem} from './global/actions/main.api';
import {useIdleTimer} from "react-idle-timer";
import {wrapperSx} from './global/helpers/globalstyles';
import {getName} from './global/helpers/text';
import {useSchoolStore} from './School/store/SchoolStore';
import SchoolsListComponent from './School/components/SchoolsListComponent';
import useLoadingHook from "./global/hooks/UseLoadingHook";
import {apiFindStudentsByIIN} from "./GovPage/actions/api";
import {
    StudentInfoType,
    TheoryExamType,
    TransactionChipStatus,
    TransactionStatus,
    TransactionType, TrialExamType
} from "./global/actions/types.api";
import {GroupStatusChip} from "./Group/components/GroupsList/GroupStatusChip";
import StartTheoryExamModal from "./Kursant/components/StartTheoryExamModal";
import {apiGetUserTransactions} from "./Payments/actions/api";
import {apiGetUserTheoryExams, apiGetUserTrialExams} from "./TheoryExam/actions/api";
import StartTrialExamModal from "./Kursant/components/StartTrialExamModal";

// TODO - разделить личные кабинеты администратора, менеджеров школ и студентов по разным страницам.

function App() {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);

    const onIdle = () => {
        if (currentUser.iin) {
            navigate('/auth');
            snackbar.infoMessage(`Сессия была прекращена в связи с бездействием.`)
            logoutFromSystem()
        }
    }

    useIdleTimer({
        onIdle,
        timeout: 60000 * 15,
        throttle: 500
    })

    useEffect(() => {
        if (currentUser.isKursant) {
            navigate('/kursant');
            return;
        }

        if (currentUser.iin && !currentUser.isKursant) {
            checkAuth()
                .then((res) => {
                    if (res.data.valid) {
                    } else {
                        currentUser.logOut();
                        snackbar.infoMessage('Прошло много времени с последнего посещения сайта. Необходимо авторизоваться повторно.');
                    }
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла ошибка обновления токена. Авторизуйтесь повторно');
                })
        } else {
            navigate('/landing')
        }
    }, []);

    const GuestMenu = () => {
        return (
            <Stack direction={'column'} width={'500px'}>
                <Button
                    sx={{p: 2, mt: 2}}
                    variant={'contained'}
                    onClick={() => {navigate('/auth')}}
                >
                    Войти в систему
                </Button>
                <Button
                    sx={{p: 2, mt: 2}}
                    variant={'contained'}
                    onClick={() => {navigate('/register')}}
                >
                    Зарегистрироваться
                </Button>
            </Stack>
        )
    }

    const ManagerMenu = () => {

        const name = getName(currentUser);

        return (
            <Stack direction={'column'} width={'100%'} sx={{mt: '38px'}}>
                <Stack flexDirection={'row'} sx={{mb: '38px'}}>
                    <Avatar
                        sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                        <Typography sx={{mt: 'auto', fontSize: '12px'}}>Учебная организация: {currentSchool.title}</Typography>
                        <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
                    </Stack>


                    <Button
                        sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>

                </Stack>

                <SchoolsListComponent withoutSearch={true}/>
            </Stack>
        )
    }

    const AdminMenu = () => {
        const name = getName(currentUser);

        return (
            <Stack direction={'column'} width={'100%'} sx={{mt: '38px', pb: '50px'}}>
                <Stack flexDirection={'row'} sx={{mb: '16px'}}>
                    <Avatar
                        sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                        <Typography sx={{mt: 'auto', fontSize: '12px'}}>Роль: Администратор</Typography>
                        <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
                    </Stack>

                    <Button
                        sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>
                </Stack>

                <Stack direction={'column'} width={'500px'} alignSelf={'center'}>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Функции администратора</b></Typography>
                    </Stack>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/admin')}}
                    >
                        Перейти к заявкам на регистрацию
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/schools')}}
                    >
                        Перейти к списку учебных организаций
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/new-school')}}
                    >
                        {currentUser.isAdmin ? 'Добавить автошколу пользователю' : 'Добавить учебную организацию'}
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/register')}}
                        color={'warning'}
                    >
                        Зарегистрировать автошколу и пользователя
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/change-password')}}
                        color={'warning'}
                    >
                        Сменить пароль пользователю
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/tickets')}}
                    >
                        Перейти в службу поддержки
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/journal')}}
                    >
                        Журнал авторизаций
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/statistic')}}
                    >
                        Статистика по учебным организациям
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/notifications')}}
                    >
                        Создать уведомление
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/corrections')}}
                    >
                        Корректировки по курсантам
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/corrections-invoices')}}
                    >
                        Счета на оплату (корректировки)
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/questions')}}
                    >
                        База вопросов
                    </Button>
                </Stack>

            </Stack>
        )
    }

    const StudentMenu = () => {

        const [currentStudentInfo, setCurrentStudentInfo] = useState<StudentInfoType | null>(null);
        const [startExamModalOpen, setStartExamModalOpen] = useState<boolean>(false);
        const [startTrialExamModalOpen, setStartTrialExamModalOpen] = useState<boolean>(false);
        const [hasGeneratedPassword, setHasGeneratedPassword] = useState<boolean>(false);

        const [studentsInfo, studentsInfoLoading, studentsInfoError, getStudentsInfo] = useLoadingHook<StudentInfoType[]>(apiFindStudentsByIIN);
        const [transactionsInfo, transactionsInfoLoading, transactionsInfoError, getTransactionsInfo] = useLoadingHook<TransactionType[]>(apiGetUserTransactions);
        const [theoryExamsInfo, theoryExamsInfoLoading, theoryExamsInfoError, getTheoryExamsInfo] = useLoadingHook<TheoryExamType[]>(apiGetUserTheoryExams);
        const [trialExamsInfo, trialExamsInfoLoading, trialExamsInfoError, getTrialExamsInfo] = useLoadingHook<TrialExamType[]>(apiGetUserTrialExams);

        const name = getName(currentUser);

        useEffect(() => {
            getStudentsInfo(currentUser.iin);
            getTransactionsInfo(currentUser.id);
            getTheoryExamsInfo(currentUser.id);
            getTrialExamsInfo(currentUser.id);
        }, []);

        useEffect(() => {
            if (studentsInfo) {
                studentsInfo.forEach((studentInfo) => {
                    if (studentInfo.generated_user_password) {
                        setHasGeneratedPassword(true);
                    }
                })
            }
        }, [studentsInfo]);

        const handleStartExam = (studentId: number) => {
            studentsInfo?.map((student) => {
                if (student.id === studentId) {
                    setCurrentStudentInfo(student);
                    setStartExamModalOpen(true);
                }
            })
        };

        return (
            <Stack
                direction={'column'}
                width={'100%'}
                sx={{
                    mt: '38px',
                    p: '52px',
                    borderRadius: '24px',
                    background: '#F5F5F5',
                }}
            >
                {hasGeneratedPassword && (<Stack
                    flexDirection={'column'}
                    sx={{
                        background: '#ffeeee',
                        borderRadius: '10px',
                        p: 5,
                        mb: 5
                    }}
                    alignItems={'center'}
                >
                    <Typography>
                        Уважаемый пользователь, на Вашей учетной записи стоит временный пароль, сгенерированный вашей
                        автошколой. В целях информационной безопасности, просим Вас сменить пароль.
                    </Typography>
                </Stack>)}
                <Stack flexDirection={'row'} sx={{mb: '38px'}}>
                    <Avatar
                        sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                        <Typography sx={{mt: 'auto', fontSize: '12px'}}>Ваша роль: Курсант</Typography>
                        <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
                    </Stack>


                    <Button
                        sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>
                </Stack>

                <Button
                    variant={'contained'}
                    sx={{
                        width: 'fit-content',
                        mb: '32px'
                    }}
                    onClick={() => {setStartTrialExamModalOpen(true)}}
                >
                    Пройти пробное тестирование
                </Button>
                <Divider />
                <Typography
                    variant={'h5'}
                    sx={{
                        mt: '32px'
                    }}
                >
                    Информация по вашему обучению в автошколах:
                </Typography>
                {studentsInfoLoading && (<Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px'
                    }}
                >
                    <CircularProgress />
                </Stack>)}
                {studentsInfo && (<Stack
                    display={'flex'}
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px',
                        gap: '24px'
                    }}
                >
                    {studentsInfo.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
                    {studentsInfo.length > 0 && studentsInfo.map((studentInfo) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    width: '500px',
                                    height: 'auto',
                                    minHeight: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#FFFFFF',
                                    py: '20px',
                                    px: '16px',
                                    gap: '16px'
                                }}
                                key={studentInfo.id}
                            >
                                <Stack
                                    flexDirection={'column'}
                                    sx={{
                                        gap: '16px'
                                    }}
                                >
                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Автошкола
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.school?.title}
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Адрес
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.school?.region}, {studentInfo.group?.school?.locality}, {studentInfo.group?.school?.address}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Divider />

                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Учебная группа
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.title}
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <GroupStatusChip status={studentInfo.group?.status} variant="chip"/>
                                    </Stack>
                                </Stack>

                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'flex-start'}
                                    gap={'24px'}
                                >
                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Категория обучения
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.category_id}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'flex-start'}
                                    gap={'24px'}
                                >
                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Дата начала обучения
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.start_date?.toString() ?? 'Н/Д'}
                                        </Typography>
                                    </Stack>

                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Дата окончания обучения
                                        </Typography>
                                        <Typography
                                            variant={'h6'}
                                        >
                                            {studentInfo.group?.end_date?.toString() ?? 'Н/Д'}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Divider />

                                <Stack
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Stack>
                                        <Typography
                                            fontSize={'14px'}
                                        >
                                            Внутришкольные экзамены
                                        </Typography>
                                    </Stack>
                                </Stack>

                                {((studentInfo?.group?.status === 1 || studentInfo?.group?.status === 2) && (
                                    <Stack>
                                        <Typography variant={'h6'}>
                                            Отсутствуют данные по внутришкольным экзаменам, т.к. не закончено теоретическое и практическое обучение.
                                        </Typography>
                                    </Stack>
                                ))}

                                {((studentInfo?.group?.status === 3 || studentInfo?.group?.status === 4) && (
                                    <Stack
                                        flexDirection={'column'}
                                        gap={'16px'}
                                    >
                                        <Stack>
                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    Устройство и техническое обслуживание
                                                </Typography>
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    {studentInfo.grades?.maintenance ? studentInfo.grades?.maintenance : 'Н/Д'}
                                                </Typography>
                                            </Stack>

                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    Правила и основы движения
                                                </Typography>
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    {studentInfo.grades?.rules ? studentInfo.grades?.rules : 'Н/Д'}
                                                </Typography>
                                            </Stack>

                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    Практическое вождение
                                                </Typography>
                                                <Typography
                                                    variant={'h6'}
                                                >
                                                    {studentInfo.grades?.practice ? studentInfo.grades?.practice : 'Н/Д'}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                ))}

                                {(!studentInfo.grades?.maintenance || studentInfo.grades?.maintenance === 2)
                                    && (studentInfo?.group?.status === 3 || studentInfo?.group?.status === 4)
                                        && (
                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'center'}
                                            >
                                                <Button
                                                    variant={'contained'}
                                                    onClick={() => {handleStartExam(studentInfo.id)}}
                                                >
                                                    Пройти экзамен
                                                </Button>
                                            </Stack>
                                )}
                            </Stack>
                        )
                    })}
                </Stack>)}
                <Divider />
                <Typography
                    variant={'h5'}
                    sx={{
                        mt: '32px'
                    }}
                >
                    История результатов внутришкольных экзаменов:
                </Typography>

                <Stack
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px',
                        gap: '24px'
                    }}
                >
                    {theoryExamsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
                    {theoryExamsInfo?.map((theoryExam) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    width: '300px',
                                    height: 'auto',
                                    minHeight: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#FFFFFF',
                                    py: '20px',
                                    px: '16px',
                                    gap: '16px'
                                }}
                                key={theoryExam.id}
                            >
                                <Typography
                                    fontSize={'14px'}
                                >
                                    Внутришкольный экзамен по теории
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    color={'gray'}
                                    sx={{
                                        mt: '-16px'
                                    }}
                                >
                                    #{theoryExam.id}
                                </Typography>
                                <Chip
                                    label={`${theoryExam.category_id} категория`}
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                />
                                {theoryExam.ended_at && (
                                    <Typography>
                                        Правильные ответы: {theoryExam.correct_answers}/{theoryExam.total_questions}
                                    </Typography>
                                )}
                                <Button
                                    variant={'contained'}
                                    onClick={() => {theoryExam.ended_at ? navigate(`/theory-exams/results/${theoryExam.id}`) : navigate(`/theory-exams/${theoryExam.id}`)}}
                                    sx={{
                                        width: 'fit-content',
                                        alignSelf: 'end'
                                    }}
                                >
                                    {theoryExam.ended_at && 'Результаты'}
                                    {!theoryExam.ended_at && 'Возобновить'}
                                </Button>
                            </Stack>
                        )
                    })}
                </Stack>
                <Divider />
                <Typography
                    variant={'h5'}
                    sx={{
                        mt: '32px'
                    }}
                >
                    История результатов пробных теоретических экзаменов:
                </Typography>

                <Stack
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px',
                        gap: '24px'
                    }}
                >
                    {trialExamsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
                    {trialExamsInfo?.map((trialExam) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    width: '300px',
                                    height: 'auto',
                                    minHeight: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#FFFFFF',
                                    py: '20px',
                                    px: '16px',
                                    gap: '16px'
                                }}
                                key={trialExam.id}
                            >
                                <Typography
                                    fontSize={'14px'}
                                >
                                    Пробный экзамен по теории
                                </Typography>
                                <Typography
                                    fontSize={'14px'}
                                    color={'gray'}
                                    sx={{
                                        mt: '-16px'
                                    }}
                                >
                                    #{trialExam.id}
                                </Typography>
                                <Chip
                                    label={`${trialExam.category_id} категория`}
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                />
                                {trialExam.ended_at && (
                                    <Typography>
                                        Правильные ответы: {trialExam.correct_answers}/{trialExam.total_questions}
                                    </Typography>
                                )}
                                <Button
                                    variant={'contained'}
                                    onClick={() => {trialExam.ended_at ? navigate(`/trial-exams/results/${trialExam.id}`) : navigate(`/trial-exams/${trialExam.id}`)}}
                                    sx={{
                                        width: 'fit-content',
                                        alignSelf: 'end'
                                    }}
                                >
                                    {trialExam.ended_at && 'Результаты'}
                                    {!trialExam.ended_at && 'Возобновить'}
                                </Button>
                            </Stack>
                        )
                    })}
                </Stack>
                <Divider
                    sx={{
                        mt: '32px'
                    }}
                />
                <Typography
                    variant={'h5'}
                    sx={{
                        mt: '32px'
                    }}
                >
                    История транзакций:
                </Typography>

                <Stack
                    flexDirection={'row'}
                    flexWrap={'wrap'}
                    sx={{
                        py: '32px',
                        gap: '24px'
                    }}
                >
                    {transactionsInfo?.length === 0 && <Chip sx={{p: 2}} label={<Typography variant={'h6'}>Нет данных</Typography>} />}
                    {transactionsInfo?.map((transaction) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    width: '300px',
                                    height: 'auto',
                                    minHeight: '150px',
                                    borderRadius: '16px',
                                    backgroundColor: '#FFFFFF',
                                    py: '20px',
                                    px: '16px',
                                    gap: '16px'
                                }}
                                key={transaction.id}
                            >
                                <Typography
                                    fontSize={'14px'}
                                >
                                    Транзакция #{transaction.id}
                                </Typography>
                                <Typography>
                                    Сумма: {transaction.price} ₸
                                </Typography>
                                <Chip
                                    label={TransactionChipStatus[transaction.status]}
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                    color={transaction.status === TransactionStatus.Paid ? 'success' : 'default'}
                                />
                                <Button
                                    variant={'contained'}
                                    onClick={() => {navigate(`/transactions/${transaction.id}`)}}
                                    sx={{
                                        width: 'fit-content',
                                        alignSelf: 'end'
                                    }}
                                >
                                    Подробнее
                                </Button>
                            </Stack>
                        )
                    })}
                </Stack>

                <StartTheoryExamModal
                    studentInfo={currentStudentInfo}
                    open={startExamModalOpen}
                    onClose={() => {setStartExamModalOpen(false)}}
                />
                <StartTrialExamModal
                    open={startTrialExamModalOpen}
                    onClose={() => {setStartTrialExamModalOpen(false)}}
                />
            </Stack>
        )
    }

    return (
        <Box className="App"
             sx={{
                 height: '100%',
                 display: 'flex',
                 flexDirection: 'column'
             }}
        >
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                {!currentUser.iin && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Информационная система</b></Typography>
                    <Typography variant={'h6'}>Для учета групп учебной организации по подготовке водителей транспортных
                        средств Республики Казахстан</Typography>
                </Stack>}

                {currentUser.iin && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}>Добро пожаловать в систему, {currentUser.last_name} {currentUser.first_name}!</Typography>
                </Stack>}

                <Stack direction={'column'} sx={{alignItems: 'center', mt: 5}}>
                    {!currentUser.iin && (<GuestMenu />)}
                    {currentUser.iin && currentUser.isAdmin && (<AdminMenu />)}
                    {currentUser.iin && !currentUser.isAdmin && !currentUser.isStudent && (<ManagerMenu />)}
                    {currentUser.iin && currentUser.isStudent && (<StudentMenu />)}
                </Stack>
            </Box>
        </Box>
    );
}

export default App;
