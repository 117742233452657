import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {Link} from "react-router-dom";
import {useUserStore} from "../store/UserStore";
import KursantIcon from '../assets/images/KursantIcon';
import { logoutFromSystem, logoutToAuth } from '../actions/main.api';
import MiniNotifications from '../../Notifications/pages/MiniNotifications';

const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        color: 'blue'
    }
}

const linkTypoStyle = {
    p: '3px 16px',
    fontWeight: '500',
    '&:hover': {
        color: '#044EBD'
    }
}

const headerSx = {
    background: 'transparent',
    display: 'flex',
    width: '100%',
    minHeight: '87px',
    pl: 5,
    pr: 5
}

interface PageObj {
    path: string,
    title: string,
}

interface PageHeaderProps {
    pages?: PageObj[];
}

const UserBar = () => {
    return (
        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
            <KursantIcon
                width={'90px'}
                height={'60px'}
            />

            <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '54px'}}>
                <Link to={'/'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Главная</Typography></Link>

                <Link to={'/schools'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Список организаций</Typography></Link>

                <Link to={'/tickets'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Служба поддержки</Typography></Link>

                <Link to={'/journal'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Журнал авторизаций</Typography></Link>
            </Stack>

            <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
                <MiniNotifications />

                <Button
                    variant={'contained'}
                    sx={{marginLeft: 'auto', p: '0px 24px'}}
                    onClick={() => {logoutFromSystem()}}
                >
                    Выйти из аккаунта
                </Button>
            </Stack>
        </Stack>
    )
}

const KursantBar = () => {
    return (
        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
            <KursantIcon
                width={'90px'}
                height={'60px'}
            />

            <Button
                variant={'contained'}
                sx={{marginLeft: 'auto', p: '0px 24px'}}
                onClick={() => {logoutToAuth()}}
            >
                Выйти из аккаунта
            </Button>
        </Stack>
    )
}

const GuestBar = () => {
    return (
        <Stack
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{background: 'transparent', py: 2}}
        >
            <KursantIcon
                width={'155px'}
                height={'100px'}
            />
        </Stack>
    )
}

const AdminBar = () => {
    return (
        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{...headerSx}}>
            <KursantIcon
                width={'90px'}
                height={'60px'}
            />

            <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={'16px'} sx={{ml: '54px'}}>
                <Link to={'/'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Главная</Typography></Link>

                <Link to={'/admin'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Заявки на регистрацию</Typography></Link>

                <Link to={'/schools'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Список организаций</Typography></Link>

                <Link to={'/admin/tickets'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Служба поддержки</Typography></Link>

                <Link to={'/journal'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>Журнал авторизаций</Typography></Link>

                <Link to={'/questions'} style={{...linkStyle}}><Typography sx={{...linkTypoStyle}}>База вопросов</Typography></Link>
            </Stack>
            
            <Stack flexDirection={'row'} gap={2} sx={{ml: 'auto'}} alignItems={'center'}>
                <MiniNotifications />

                <Button
                    variant={'contained'}
                    sx={{marginLeft: 'auto', p: '0px 24px'}}
                    onClick={() => {logoutFromSystem()}}
                >
                    Выйти из аккаунта
                </Button>
            </Stack>
        </Stack>
    )
}

const PageHeader = ({pages}: PageHeaderProps) => {

    const currentUser = useUserStore((state) => state);
    const mode = process.env.REACT_APP_MODE ? process.env.REACT_APP_MODE : 'dev';

    if (!currentUser.iin) {
        return <GuestBar />
    }

    if (currentUser.iin && !currentUser.isAdmin && !currentUser.isKursant) {
        return <UserBar />
    }

    if (currentUser.iin && currentUser.isAdmin) {
        return <AdminBar />
    }

    if (currentUser.iin && currentUser.isKursant) {
        return <KursantBar />
    }

    return (
        <Stack
            direction={'row'}
            spacing={1}
            sx={{alignItems: 'center', justifyContent: 'space-between'}}
        >
            <Stack>
                <Typography sx={{fontWeight: 'bold'}}>ИС Курсант</Typography>
                <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                    {pages?.map((page, index) => {

                        if (index === pages.length - 1) {
                            return (
                                <Link to={page.path} key={index} className={'navigation-link'}><Typography className={'navigation-p'}>{page.title}</Typography></Link>
                            )
                        }

                        return (
                            <React.Fragment key={index}>
                                <Link to={page.path} className={'navigation-link'}><Typography className={'navigation-p'}>{page.title}</Typography></Link>
                                <Typography>{' / '}</Typography>
                            </React.Fragment>
                        )
                    })}
                </Stack>
            </Stack>

            {mode === 'dev' && (
                <Box>
                    <Typography sx={{color: 'gray', fontWeight: 'bold'}}>
                        Тестовый стенд
                    </Typography>
                </Box>
            )}

            {currentUser.iin && !currentUser.isAdmin && <UserBar />}
            {currentUser.iin && currentUser.isAdmin && <AdminBar />}
            {!currentUser.iin && <GuestBar />}
        </Stack>
    );
};

export default PageHeader;