import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogContent, DialogTitle, Divider, Stack, Tooltip, Typography} from "@mui/material";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {StudentInfoType} from "../../global/actions/types.api";
import LoadingButton from "../../global/components/LoadingButton";
import InfoIcon from "@mui/icons-material/Info";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiRequestExam, CreateTheoryExamResponse} from "../../TheoryExam/actions/api";
import {useNavigate} from "react-router-dom";

interface ModalProps {
    studentInfo: StudentInfoType | null,
    open: boolean,
    onClose: () => void,
}

const StartTheoryExamModal = ({studentInfo, open, onClose} : ModalProps) => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const [requestExamResponse, requestExamLoading, requestExamError, requestExamInfo] = useLoadingHook<CreateTheoryExamResponse>(apiRequestExam);

    const handleRequestExam = () => {
        if (studentInfo) {
            requestExamInfo({
                student_id: studentInfo.id,
                category_id: studentInfo.group?.category_id,
            });
        }
    };

    const handleNavigateToPurchasePage = () => {
        navigate('/purchase', {
            state: {
                purchaseType: 'theory_exams'
            }
        })
    }

    useEffect(() => {
        if (requestExamResponse) {
            snackbar.successMessage(requestExamResponse.message);
            navigate('/theory-exams/' + requestExamResponse.theory_exam_id);
        }

        if (requestExamError) {
            snackbar.errorMessage(requestExamError.response?.data?.message ? requestExamError.response?.data?.message : 'Произошла непредвиденная ошибка');
        }
    }, [requestExamResponse, requestExamError]);

    if (studentInfo) {
        return (
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    Экзамен по теории студента {studentInfo.last_name} {studentInfo.first_name}
                </DialogTitle>
                <DialogContent>
                    <Stack
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <Stack>
                            <Typography variant={'h6'}>
                                Текущие экзаменационные оценки за теоретические предметы:
                            </Typography>
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                                gap={'7px'}
                            >
                                <Typography>
                                    Устройство и техническое обслуживание -
                                </Typography>
                                <Typography>
                                    {studentInfo.grades?.maintenance ? studentInfo.grades?.maintenance : 'Н/Д'}
                                </Typography>
                            </Stack>

                            <Stack
                                flexDirection={'row'}
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                                gap={'7px'}
                            >
                                <Typography>
                                    Правила и основы движения -
                                </Typography>
                                <Typography>
                                    {studentInfo.grades?.maintenance ? studentInfo.grades?.maintenance : 'Н/Д'}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider />

                        <Stack>
                            <Typography variant={'h6'}>
                                Информация по попыткам сдачи экзаменов:
                            </Typography>
                            <Typography>
                                Количество бесплатных попыток - {studentInfo.free_tries}
                            </Typography>
                            <Typography>
                                Количество платных попыток - {studentInfo.paid_tries}
                            </Typography>
                            <Typography>
                                Количество использованных попыток - {(studentInfo?.used_free_tries ?? 0) + (studentInfo?.used_paid_tries ?? 0)}
                            </Typography>
                        </Stack>

                        <Divider />

                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Stack
                                flexDirection={'row'}
                                gap={'16px'}
                                alignItems={'center'}
                            >
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                    loading={requestExamLoading}
                                    onClick={handleRequestExam}
                                >
                                    Начать прохождение экзамена
                                </LoadingButton>
                                <Tooltip title={'На прохождение экзамена дается 40 минут. Правильные ответы можно увидеть только по окончании экзамена. Если вы случайным образом закрыли страницу прохождения тестирования - вы можете вернуться на нее в личном кабинете, нажав на кнопку "Пройти экзамен".'}>
                                    <InfoIcon sx={{color: 'gray'}}/>
                                </Tooltip>
                            </Stack>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 'fit-content'
                                }}
                                loading={requestExamLoading}
                                onClick={() => {handleNavigateToPurchasePage()}}
                            >
                                Получить больше попыток
                            </LoadingButton>
                        </Stack>

                    </Stack>
                </DialogContent>
            </Dialog>
        );
    } else {
        return (
            <></>
        );
    }
};

export default StartTheoryExamModal;