import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";
import {ProductType} from "../../global/actions/types.api";

export const apiGetProductsPrice = () => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/products-prices`);
}

export const apiRequestTransaction = (dto: TransactionCreateDto) => {
    return axiosInstance.post(`${BACKEND_URL}/transactions/create`, dto);
}

export const apiGetTransaction = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/${transactionId}`);
}

export const apiRefreshTransactionStatus = (transactionId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/refresh/${transactionId}`);
}

export const apiGetUserTransactions = (userId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/transactions/user/${userId}`);
}

// ----------------- DTO

export interface TransactionCreateDto {
    user_id?: number;
    recipient_id: number | null;
    school_id?: number | null;
    type: ProductType;
    amount: number;
    price: number;
}