import React, {useEffect, useState} from 'react';
import {useSnackbarStore} from '../../global/store/SnackbarStore';
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card, Chip, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetTrialExam} from "../actions/api";
import {TrialExamStatus, TrialExamType} from "../../global/actions/types.api";
import PageHeader from "../../global/components/PageHeader";
import {formatDateTime, formatDateTimeSeconds} from "../../global/helpers/text";

const TrialExamResultsPage = () => {
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
    const {id} = useParams();

    const [trialExamInfo, trialExamLoading, trialExamError, fetchTrialExam] = useLoadingHook<TrialExamType>(apiGetTrialExam);
    const [grade, setGrade] = useState<number | null>(null);

    useEffect(() => {
        fetchTrialExam(Number(id));
    }, [id]);

    useEffect(() => {
        if (trialExamInfo) {
            if (trialExamInfo.status === TrialExamStatus.Created || trialExamInfo.status === TrialExamStatus.InProgress) {
                snackbar.infoMessage('Данный экзамен еще не завершен, перенаправляем Вас на страницу прохождения экзамена.')
                navigate('/trial-exams/' + trialExamInfo.id);
            } else {
                const correctPercentage = ((trialExamInfo.correct_answers / trialExamInfo.total_questions) * 100)

                if (correctPercentage > 60 && correctPercentage < 80) {
                    setGrade(3);
                } else if (correctPercentage >= 80 && correctPercentage < 90) {
                    setGrade(4);
                } else if (correctPercentage >= 90) {
                    setGrade(5);
                } else {
                    setGrade(2);
                }
            }
        }
    }, [trialExamInfo]);

    if (trialExamLoading) {
        return (
            <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
                <PageHeader />
                <Stack
                    flexDirection={'column'}
                    sx={{
                        background: '#fff',
                        m: '32px',
                        p: '32px',
                        borderRadius: '16px',
                        minHeight: '600px'
                    }}
                    gap={'32px'}
                >
                    <CircularProgress />
                </Stack>
            </Box>
        )
    }

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
            <PageHeader />

            <Stack
                flexDirection={'column'}
                sx={{
                    background: '#fff',
                    m: '32px',
                    p: '32px',
                    borderRadius: '16px',
                    minHeight: '600px'
                }}
                gap={'32px'}
            >
                <Typography
                    fontWeight={'bold'}
                >
                    Результаты пробного экзамена #{trialExamInfo?.id}.
                </Typography>

                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography>
                        Общее количество вопросов - {trialExamInfo?.total_questions}
                    </Typography>
                    <Typography>
                        Количество правильных ответов - {trialExamInfo?.correct_answers} / {trialExamInfo?.total_questions}
                    </Typography>
                </Stack>

                <Divider />

                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography>
                        Предварительная оценка за предмет "Устройство и техническое обслуживание" - {grade}
                    </Typography>
                    <Typography>
                        Предварительная оценка за предмет "Правила и основы движения" - {grade}
                    </Typography>
                </Stack>

            </Stack>
        </Box>
    );
};

export default TrialExamResultsPage;
