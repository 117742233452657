import {BACKEND_URL} from '../../global/actions/apiVariables';
import {MedicalInfoDto, StudentAddDto, StudentDeleteDto} from "./student.type";
import axiosInstance from "../../global/actions/main.api";
import {
    GroupAttachDriverDto,
    GroupAttachMasterDto,
    GroupAttachTeacherDto,
    GroupAttachTransportDto,
    GroupChangeCategoryDto,
    GroupChangeDatesDto,
    GroupChangeTitleDto
} from "./group.type";
import {StudentGradeDto} from "./grades.type";

export const apiGetExamGroups = () => {
    return axiosInstance.get(`${BACKEND_URL}/groups/exams`);
}

export const getGroupsBySchoolId = (schoolId: number, filter: string, limit: number, offset: number, status: number) => {
    let queryString = `?limit=${limit}&offset=${offset}&status=${status}`;

    if (filter) {
        queryString += `&search=${filter}`;
    }

    return axiosInstance.get(`${BACKEND_URL}/groups/all-groups/${schoolId}${queryString}`);
}


export const createGroupApi = (groupForm: any) => {
    return axiosInstance.post(`${BACKEND_URL}/groups`, groupForm);
}

export const deleteGroupApi = (groupId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/delete/${groupId}`, {});
}

export const getAllCategories = (schoolId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/categories/${schoolId}`);
}

export const getGroupById = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/groups/${groupId}`);
}

export const addStudentToGroup = (studentDto: StudentAddDto) => {
    return axiosInstance.post(`${BACKEND_URL}/students`, studentDto);
}

export const changeGroupDates = (dto: GroupChangeDatesDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/change-dates`, dto)
}

export const changeGroupTitle = (dto: GroupChangeTitleDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/change-title`, dto)
}

export const changeGroupCategory = (dto: GroupChangeCategoryDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/change-category`, dto)
}

export const deleteStudentFromGroup = (studentId: number) => {
    const studentDto: StudentDeleteDto = {
        student_id: studentId
    }

    return axiosInstance.post(`${BACKEND_URL}/students/delete`, studentDto);
}

export const startGroupApi = (groupId: number) => {
    const groupDto = {
        group_id: groupId,
        status: 2
    }

    return axiosInstance.post(`${BACKEND_URL}/groups/change-status`, groupDto);
}

export const startExamsGroupApi = (groupId: number) => {
    const groupDto = {
        group_id: groupId,
        status: 3
    }

    return axiosInstance.post(`${BACKEND_URL}/groups/change-status`, groupDto);
}

export const finishGroupApi = (groupId: number) => {
    const groupDto = {
        group_id: groupId,
        status: 4
    }

    return axiosInstance.post(`${BACKEND_URL}/groups/change-status`, groupDto);
};

export const createCertificatesForGroup = (groupId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/certificate/create/all/${groupId}`, {});
}

export const successExamApi = (studentId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/students/exam/success/${studentId}`, {})
}

export const failExamApi = (studentId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/students/exam/failure/${studentId}`, {})
}

export const attachTeacherToGroup = (teacherDto: GroupAttachTeacherDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/attach/teacher`, teacherDto)
}

export const attachDriverToGroup = (driverDto: GroupAttachDriverDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/attach/driver`, driverDto)
}

export const attachMasterToGroup = (masterDto: GroupAttachMasterDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/attach/master`, masterDto)
}

export const attachTransportToGroup = (transportDto: GroupAttachTransportDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/attach/transport`, transportDto)
}

export const detachTeacherFromGroup = (teacherDto: GroupAttachTeacherDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/detach/teacher`, teacherDto)
}

export const detachDriverFromGroup = (driverDto: GroupAttachDriverDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/detach/driver`, driverDto)
}

export const detachMasterFromGroup = (masterDto: GroupAttachMasterDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/detach/master`, masterDto)
}

export const detachTransportFromGroup = (transportDto: GroupAttachTransportDto) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/detach/transport`, transportDto)
}

export const getServerDate = () => {
    return axiosInstance.get(`${BACKEND_URL}/groups/get/date`);
}

// Свидетельства об окончании обучения
export const getCertificateInfoForCheck = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/check/${studentId}`, { responseType: 'json' });
}

export const getCertificate = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/${studentId}`, { responseType: 'arraybuffer' })
}

export const getCertificateA4 = (studentId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/a4/pdf/${studentId}`, { responseType: 'arraybuffer' })
}

export const getEnrollment = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/enrollment/${groupId}`, { responseType: 'arraybuffer' })
}

export const getExamsProtocol = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/protocol/${groupId}`, { responseType: 'arraybuffer' })
}

export const getGraduation = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/certificate/pdf/graduation/${groupId}`, { responseType: 'arraybuffer' })
}

// Заявки на корректировку

export const getAllCorrectionsByGroupId = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/corrections/group/${groupId}`)
}

export const createCorrection = (dto: MedicalInfoDto) => {
    return axiosInstance.post(`${BACKEND_URL}/students/medical-info`, dto);
}

// Оценки и экзамены
export const submitGradeByStudentId = (dto: StudentGradeDto, schoolId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/students/grade/${dto.student_id}?schoolId=${schoolId}`, dto);
}

// Занятия
export const getClassesByGroupId = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/groups/classes/${groupId}`);
}

export const getTheoryHoursByGroupId = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/groups/theory-hours/${groupId}`);
}

export const getPracticeHoursByGroupId = (groupId: number) => {
    return axiosInstance.get(`${BACKEND_URL}/groups/practice-hours/${groupId}`);
}

export const getSectionsAndTopicByCategory = (categoryId: string) => {
    return axiosInstance.get(`${BACKEND_URL}/categories/sections-topics/${categoryId}`);
}

export const getCabinets = (schoolId: number, approved: boolean) => {
    return axiosInstance.get(`${BACKEND_URL}/cabinets/school/${schoolId}?approved=${approved}`);
}

export const submitClass = (dto: any) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/submit-class`, dto);
}

// ----- CreateStudentUserModal.tsx -----

export const apiGetStudentUserByIIN = (iin: string) => {
    return axiosInstance.get(`${BACKEND_URL}/users/students/${iin}`);
}

export const apiCreateUserForStudent = (dto: any) => {
    return axiosInstance.post(`${BACKEND_URL}/students/create-user`, dto);
}

export const apiAttachStudentToUser = (dto: any) => {
    return axiosInstance.post(`${BACKEND_URL}/students/attach-user`, dto);
}

export const apiDeleteClass = (dto: any) => {
    return axiosInstance.post(`${BACKEND_URL}/groups/delete-class`, dto);
}