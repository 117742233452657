import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    Box, Chip,
    CircularProgress,
    Stack,
    Typography
} from '@mui/material';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { useUserStore } from '../../global/store/UserStore';
import PageHeader from '../../global/components/PageHeader';
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetTransaction, apiRefreshTransactionStatus} from "../actions/api";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {CheckCircleOutline, WarningAmberOutlined, WarningOutlined} from "@mui/icons-material";
import {TransactionChipStatus, TransactionType} from '../../global/actions/types.api';

const BuyingPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {transactionId} = useParams();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    const [transactionResponse, transactionLoading, transactionError, requestTransaction] = useLoadingHook<TransactionType>(apiGetTransaction);
    const [refreshResponse, refreshLoading, refreshError, requestRefresh] = useLoadingHook(apiRefreshTransactionStatus);
    const [refreshTries, setRefreshTries] = useState<number>(0);

    useEffect(() => {
        if (transactionId) {
            requestTransaction(transactionId)
        }
    }, [transactionId]);

    useEffect(() => {
        if (transactionResponse) {
            console.log(transactionResponse);
        }

        if (transactionError) {
            snackbar.errorMessage(transactionError?.response?.data?.message ?? 'Произошла непредвиденная ошибка при получении данных о транзакции');
        }
    }, [transactionResponse, transactionError]);

    useEffect(() => {
        if (transactionResponse?.status === 1 && refreshTries === 0) {
            console.log(`currentRefreshTries: ${refreshTries}`)

            requestRefresh(transactionId);
        } else if (transactionResponse?.status === 1 && refreshTries > 0 && refreshTries < 5) {
            console.log(`currentRefreshTries: ${refreshTries}`)

            setTimeout(() => {
                requestRefresh(transactionId);
            }, 10000)
        }
    }, [transactionResponse, refreshTries]);

    useEffect(() => {
        if (refreshResponse?.status !== 2) {
            snackbar.infoMessage('Заказ все еще не оплачен. Обновим информацию через 10 секунд.');
            setRefreshTries((prev) => prev + 1);
        }
    }, [refreshResponse, refreshError])

    useEffect(() => {
        if (refreshResponse?.status === 2) {
            requestTransaction(transactionId);
        }
    }, [refreshResponse]);

    if (transactionLoading) {
        return (
            <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader/>

                <Box sx={{...wrapperSx}}>
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <CircularProgress />
                    </Stack>
                </Box>
            </Box>
        );
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader/>

            <Box sx={{...wrapperSx}}>
                <Stack
                    direction={'column'}
                    width={'100%'}
                    sx={{
                        mt: '38px',
                        p: '52px',
                        borderRadius: '24px',
                        background: '#F5F5F5',
                        gap: '16px'
                    }}
                >
                    <Typography fontSize={'14px'}>
                        Транзакция #{transactionId}
                    </Typography>
                    <Typography
                        variant={'h5'}
                    >
                        Приобретение {transactionResponse?.type === 'theory_exams' && 'попыток прохождения внутришкольного экзамена'}
                    </Typography>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <Typography>
                            Количество приобретаемых попыток:
                        </Typography>
                        <Chip label={transactionResponse?.amount}/>
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                    >
                        <Typography>
                            Статус транзакции:
                        </Typography>
                        <Chip
                            label={transactionResponse?.status && TransactionChipStatus[transactionResponse?.status]}
                        />
                    </Stack>

                    {transactionResponse?.status === 1 && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                        >
                            <Typography>
                                Ссылка на страницу оплаты:
                            </Typography>
                            <Chip
                                label={transactionResponse?.payment_url}
                                onClick={() => {window.location.href = transactionResponse?.payment_url}}
                                sx={{
                                    cursor: 'pointer'
                                }}
                            />
                        </Stack>
                    )}

                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'16px'}
                        sx={{
                            mb: '8px'
                        }}
                    >
                        <Typography
                            variant={'h6'}
                        >
                            Стоимость: {transactionResponse?.price} ₸
                        </Typography>
                    </Stack>

                    {transactionResponse?.status === 1 && refreshLoading && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#e3e3e3',
                                width: 'fit-content'
                            }}
                        >
                            <CircularProgress />
                            <Typography>
                                Проверяем платеж...
                            </Typography>
                        </Stack>
                    )}

                    {transactionResponse?.status === 1 && !refreshLoading && refreshTries < 5 && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#fa8484',
                                width: 'fit-content'
                            }}
                        >
                            <WarningAmberOutlined
                                fontSize={'large'}
                            />
                            <Typography>
                                Заказ все еще не оплачен. Обновим информацию через 10 секунд.
                            </Typography>
                        </Stack>
                    )}


                    {(transactionResponse?.status === 1) && !refreshLoading && (refreshTries >= 5) && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: '#fa8484',
                                width: 'fit-content'
                            }}
                        >
                            <WarningAmberOutlined
                                fontSize={'large'}
                            />
                            <Stack>
                                <Typography>
                                    Заказ не оплачен. Перейдите по ссылке на страницу оплаты и произведите оплату.
                                </Typography>
                                <Typography>
                                    Если на странице оплаты - отображен статус "Заказ оплачен" - пожалуйста, обратитесь в службу поддержки.
                                </Typography>
                            </Stack>
                        </Stack>
                    )}

                    {transactionResponse?.status === 2 && currentUser.id === transactionResponse.user_id && (
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                p: '32px',
                                borderRadius: '16px',
                                background: 'rgba(203,255,192,0.78)',
                                width: 'fit-content'
                            }}
                        >
                            <CheckCircleOutline fontSize={'large'} color={'secondary'}/>
                            <Typography>
                                Поздравляем! Вы успешно приобрели попытки ({transactionResponse?.amount}) для прохождения {transactionResponse?.type === 'theory_exams' && 'внутришкольных экзаменов'}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

export default BuyingPage;
