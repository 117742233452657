import {create} from "zustand";
import {IRole} from "../../Auth/dto/AuthDto";
import {createJSONStorage, persist} from "zustand/middleware";

export interface IUSerInfo {
    email: string;
    first_name: string;
    last_name: string;
    id: number | null;
    iin: string;
    roles: IRole[];
    isAdmin: boolean;
    isKursant: boolean;
    isStudent: boolean;
    setUserInfo: (userInfo: any) => void;
    setIsAdmin: (isAdmin: boolean) => void;
    setIsKursant: (isKursant: boolean) => void;
    setIsStudent: (isStudent: boolean) => void;
    logOut: () => void;
}


export const useUserStore = create<IUSerInfo>()(
    persist(
        (set) => ({
            email: "",
            first_name: "",
            last_name: "",
            id: null,
            iin: "",
            roles: [],
            isAdmin: false,
            isKursant: false,
            isStudent: false,
            setUserInfo: (userInfo) => set((state) => ({
                email: userInfo.email,
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                id: userInfo.id,
                iin: userInfo.iin,
                roles: userInfo.roles
            })),
            setIsAdmin: (isAdmin) => set((state) => ({
                isAdmin
            })),
            setIsKursant: (isKursant) => set((state) => ({
                isKursant
            })),
            setIsStudent: (isStudent) => set((state) => ({
                isStudent
            })),
            logOut: () => set(() => ({
                email: "",
                first_name: "",
                last_name: "",
                id: null,
                iin: "",
                roles: [],
                isAdmin: false,
                isKursant: false,
                isStudent: false
            }))
        }),
        {
            name: 'user-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
)