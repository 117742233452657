import React, {useEffect, useRef, useState} from 'react';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {
    apiFinishTrialExam,
    apiGetTrialExam,
    apiSubmitTrialAnswer
} from "../actions/api";
import TimerComponent from "../components/ExamTimer";
import {TrialExamQuestionType, TrialExamType} from "../../global/actions/types.api";
import PageHeader from "../../global/components/PageHeader";
import VideoPlayer from "../components/VideoPlayer";
import LoadingButton from "../../global/components/LoadingButton";
import {PDDTEST_MEDIA_API_URL, PROCTORING_URL} from "../../global/actions/apiVariables";
import TrialExamBeginningModal from "../components/TrialExamBeginningModal";

interface handleStartProps {
    began_at: Date,
    current_time: Date
}

interface setTimerPropsType {
    began_at: Date | null,
    current_time: Date | null
}

enum AnswersButtonStartSymbols {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6
}

const TrialExamPage = () => {
    const snackbar = useSnackbarStore((state) => state);
    const navigate = useNavigate();
    const {id} = useParams();

    const [trialExamInfo, trialExamLoading, trialExamError, fetchTrialExam] = useLoadingHook<TrialExamType>(apiGetTrialExam);
    const [submitAnswerResult, submitAnswerLoading, submitAnswerError, submitAnswer] = useLoadingHook(apiSubmitTrialAnswer);
    const [finishExamResult, finishExamLoading, finishExamError, requestFinishExam] = useLoadingHook(apiFinishTrialExam);

    const [beginnigModalOpen, setBeginningModalOpen] = useState<boolean>(true);
    const [timerProps, setTimerProps] = useState<setTimerPropsType>({
        began_at: null,
        current_time: null,
    });
    const [examStarted, setExamStarted] = useState<boolean>(false);

    const [currentQuestionOrder, setCurrentQuestionOrder] = useState<number | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<TrialExamQuestionType | null>(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);

    useEffect(() => {
        fetchTrialExam(Number(id));
    }, [id]);

    useEffect(() => {
        if (trialExamInfo) {
            // Выбор первого вопроса, на который не ответил пользователь
            const unansweredOrder = getFirstUnansweredQuestionOrder();

            if (unansweredOrder) {
                const unansweredQuestion = unansweredOrder ? getQuestionByOrder(unansweredOrder) : null;

                unansweredOrder && setCurrentQuestionOrder(unansweredOrder);
                unansweredQuestion && unansweredOrder && setCurrentQuestion(unansweredQuestion);
            } else {
                finishExam();
            }
        }
        if (trialExamError) {
            snackbar.errorMessage(trialExamError.response?.data?.message ? trialExamError.response?.data?.message : 'Произошла непредвиденная ошибка');
        }
    }, [trialExamInfo, trialExamError]);

    const handleBeginExam = async (timerProps: handleStartProps) => {
        setTimerProps({
            current_time: new Date(timerProps.current_time),
            began_at: new Date(timerProps.began_at),
        });

        setExamStarted(true);
    };

    const getQuestionByOrder = (questionOrder: number) => {
        if (trialExamInfo) {
            // Используем find вместо map, чтобы найти вопрос по ID
            return trialExamInfo.questions.find((question) => question.order === questionOrder) || null;
        } else {
            return null;
        }
    };

    const getFirstUnansweredQuestionOrder = () => {
        if (trialExamInfo) {
            // Используем find для нахождения первого вопроса без ответа
            const unansweredQuestion = trialExamInfo.questions.find((question) => !question.submitted_answer_id);
            if (unansweredQuestion) {
                console.log(`У вопроса ${unansweredQuestion.id} отсутствует ответ`);
                return unansweredQuestion.order;
            }

            return null; // Возвращаем null, если все вопросы имеют ответы
        } else {
            return null;
        }
    };

    const handleSelectAnswer = (answerId: number) => {
        setSelectedAnswerId(answerId);
    }

    const handleSubmitAnswer = () => {
        if (currentQuestion) {
            submitAnswer({
                question_id: currentQuestion.id,
                answer_id: selectedAnswerId
            })
        }
    };

    useEffect(() => {
        if (submitAnswerResult) {
            if (currentQuestionOrder && currentQuestionOrder !== trialExamInfo?.questions.length) {
                setCurrentQuestionOrder(currentQuestionOrder + 1);
                setCurrentQuestion(getQuestionByOrder(currentQuestionOrder +1));
            } else if (currentQuestionOrder && currentQuestionOrder === trialExamInfo?.questions.length) {
                finishExam()
            }

            setSelectedAnswerId(null);
        }

        if (submitAnswerError) {
            snackbar.errorMessage(submitAnswerError.response.data.message ?? 'Произошла непредвиденная ошибка');
        }
    }, [submitAnswerResult, submitAnswerError]);

    useEffect(() => {
        if (finishExamResult) {
            snackbar.successMessage(finishExamResult.message);
            navigate('/trial-exams/results/' + trialExamInfo?.id);
        }

        if (finishExamError) {
            snackbar.errorMessage(finishExamError.response.data.message ?? 'Произошла непредвиденная ошибка');
        }
    }, [finishExamResult, finishExamError]);

    const handleTimeExpire = () => {
        snackbar.infoMessage(`Время истекло - экзамен завершился.`);
        requestFinishExam(Number(trialExamInfo?.id))
    };

    const finishExam = () => {
        requestFinishExam(Number(trialExamInfo?.id));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    },  [currentQuestion, selectedAnswerId]);

    const handleKeyDown = (event: KeyboardEvent) => {
        const key = event.key;

        if (key >= '1' && key <= '9') {
            const answerIndex = Number(key) - 1;
            if (currentQuestion?.answers && answerIndex < currentQuestion.answers.length) {
                setSelectedAnswerId(currentQuestion.answers[answerIndex].id);
            }
        } else if (key === 'Enter' && selectedAnswerId !== null) {
            handleSubmitAnswer();
        }
    };

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>

            <PageHeader />

            {examStarted && timerProps.began_at && timerProps.current_time && (
                <Stack
                    flexDirection={'column'}
                >

                    <Stack
                        flexDirection={'row'}
                        gap={'10px'}
                        sx={{
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            my: '16px',
                            mx: '60px',
                            scrollSnapType: 'x mandatory' // Добавляем snapping для горизонтальной оси
                        }}
                    >
                        {trialExamInfo?.questions?.map((question) => {
                            return (
                                <Stack
                                    sx={{
                                        width: '40px',
                                        minWidth: '40px',
                                        height: '40px',
                                        borderRadius: '10px',
                                        backgroundColor: question.order === currentQuestionOrder ? '#ff6a00' : '#f8a76e',
                                    }}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    key={question.order}
                                >
                                    <Typography
                                        sx={{
                                            color: 'white'
                                        }}
                                    >
                                        {question.order}
                                    </Typography>
                                </Stack>
                            )
                        })}
                    </Stack>

                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-evenly'}
                        alignItems={'center'}
                        sx={{
                            py: '24px'
                        }}
                    >
                        <Stack
                            flexDirection={'column'}
                            alignItems={'center'}
                            gap={'16px'}
                            sx={{
                                width: 'fit-content',
                                maxWidth: '50%'
                            }}
                        >
                            <Typography
                                textAlign={'center'}
                            >
                                Вопрос {currentQuestion?.order}/{trialExamInfo?.total_questions}
                            </Typography>
                            <Typography
                                variant={'h5'}
                                textAlign={'center'}
                                sx={{
                                    maxWidth: '50%'
                                }}
                            >
                                {currentQuestion?.ru_question}
                            </Typography>
                            <VideoPlayer
                                src={`${PDDTEST_MEDIA_API_URL ? PDDTEST_MEDIA_API_URL : 'https://media.pddtest.kz'}/${currentQuestion?.task_file_id}.mp4`}
                            />
                        </Stack>

                        <Stack
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                width: 'fit-content'
                            }}
                            gap={'16px'}
                        >
                            <TimerComponent
                                began_at={timerProps.began_at}
                                current_time={timerProps.current_time}
                                onExpire={() => {handleTimeExpire()}}
                            />

                            {currentQuestion?.answers?.map((answer, index) => {
                                return (
                                    <Button
                                        sx={{
                                            minWidth: '300px',
                                            justifyContent: 'start',
                                            p: '30px',
                                            flexGrow: 1,
                                            width: '100%',
                                        }}
                                        variant={selectedAnswerId === answer.id ? 'contained' : 'outlined'}
                                        disabled={submitAnswerLoading || finishExamLoading}
                                        onClick={() => handleSelectAnswer(answer.id)}
                                        key={answer.id}
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            {AnswersButtonStartSymbols[index + 1]}. {answer.ru_text}
                                        </Typography>
                                    </Button>
                                )
                            })}
                            <LoadingButton
                                sx={{
                                    minWidth: '300px',
                                    justifyContent: 'start',
                                    px: '16px'
                                }}
                                variant={'outlined'}
                                loading={submitAnswerLoading || finishExamLoading}
                                disabled={!selectedAnswerId}
                                onClick={() => handleSubmitAnswer()}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                >
                                    Ответить
                                </Typography>
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}

            <TrialExamBeginningModal
                examInfo={trialExamInfo}
                open={beginnigModalOpen}
                onClose={() => {setBeginningModalOpen(false)}}
                handleStartExam={handleBeginExam}
            />

        </Box>
    );
};

export default TrialExamPage;
