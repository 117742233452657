import {Box} from '@mui/material';
import React, {useEffect} from 'react';
import PageHeader from "../../global/components/PageHeader";
import SchoolsListComponent from '../components/SchoolsListComponent';
import {wrapperSx} from "../../global/helpers/globalstyles";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";

const SchoolsList = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    useEffect(() => {
        if (currentUser.isStudent) {
            snackbar.errorMessage('У студентов нет доступа к данной странице. Возвращаем вас в личный кабинет.')
            navigate('/')
        }
    }, []);

    const pagesObj = [
        {
            path: '/',
            title: 'Главная'
        }
    ]

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                <SchoolsListComponent />
            </Box>
        </Box>
    );
};

export default SchoolsList;
