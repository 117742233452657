import React, {useEffect, useState} from 'react';
import {useSnackbarStore} from '../../global/store/SnackbarStore';
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card, Chip, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiGetTheoryExam} from "../actions/api";
import {TheoryExamStatus, TheoryExamType} from "../../global/actions/types.api";
import PageHeader from "../../global/components/PageHeader";
import {formatDateTime, formatDateTimeSeconds} from "../../global/helpers/text";

const TheoryExamResultsPage = () => {
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const navigate = useNavigate();
    const {id} = useParams();

    const [theoryExamInfo, theoryExamLoading, theoryExamError, fetchTheoryExam] = useLoadingHook<TheoryExamType>(apiGetTheoryExam);
    const [grade, setGrade] = useState<number | null>(null);

    useEffect(() => {
        fetchTheoryExam(Number(id));
    }, [id]);

    useEffect(() => {
        if (theoryExamInfo) {
            if (theoryExamInfo.status === TheoryExamStatus.Created || theoryExamInfo.status === TheoryExamStatus.InProgress) {
                snackbar.infoMessage('Данный экзамен еще не завершен, перенаправляем Вас на страницу прохождения экзамена.')
                navigate('/theory-exams/' + theoryExamInfo.id);
            } else {
                const correctPercentage = ((theoryExamInfo.correct_answers / theoryExamInfo.total_questions) * 100)

                if (correctPercentage > 60 && correctPercentage < 80) {
                    setGrade(3);
                } else if (correctPercentage >= 80 && correctPercentage < 90) {
                    setGrade(4);
                } else if (correctPercentage >= 90) {
                    setGrade(5);
                } else {
                    setGrade(2);
                }
            }
        }
    }, [theoryExamInfo]);

    if (theoryExamLoading) {
        return (
            <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
                <PageHeader />
                <Stack
                    flexDirection={'column'}
                    sx={{
                        background: '#fff',
                        m: '32px',
                        p: '32px',
                        borderRadius: '16px',
                        minHeight: '600px'
                    }}
                    gap={'32px'}
                >
                    <CircularProgress />
                </Stack>
            </Box>
        )
    }

    return (
        <Box sx={{pt: 1, pr: 5, pl: 5, pb: 5}}>
            <PageHeader />

            <Stack
                flexDirection={'column'}
                sx={{
                    background: '#fff',
                    m: '32px',
                    p: '32px',
                    borderRadius: '16px',
                    minHeight: '600px'
                }}
                gap={'32px'}
            >
                <Typography
                    fontWeight={'bold'}
                >
                    Результаты экзамена #{theoryExamInfo?.id}. Студент - {theoryExamInfo?.student?.last_name} {theoryExamInfo?.student?.first_name}
                </Typography>

                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography>
                        Общее количество вопросов - {theoryExamInfo?.total_questions}
                    </Typography>
                    <Typography>
                        Количество правильных ответов - {theoryExamInfo?.correct_answers} / {theoryExamInfo?.total_questions}
                    </Typography>
                </Stack>

                <Divider />

                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography>
                        Оценка за предмет "Устройство и техническое обслуживание" - {grade}
                    </Typography>
                    <Typography>
                        Оценка за предмет "Правила и основы движения" - {grade}
                    </Typography>
                </Stack>

                <Divider />

                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    <Typography>
                        Нарушения в ходе прокторинга:
                    </Typography>
                    <Stack
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                        gap={'16px'}
                    >
                        {theoryExamInfo?.proctoring?.errors?.map((error) => {
                            return (
                                <Card
                                    sx={{
                                        width: '250px',
                                        height: '250px',
                                        p: 1,
                                        borderRadius: '8px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '16px'
                                    }}
                                    key={error.id}
                                >
                                    <Chip color={'error'} label={`Нарушение - ${error.error}`} />
                                    {error.photoLink && <img src={error.photoLink} style={{width: '100%', height: 'auto'}} alt={'123'}/>}
                                    <Typography fontSize={'14px'}>Время нарушения - {formatDateTimeSeconds(error.dateCreate)}</Typography>
                                </Card>
                            )
                        })}
                        {theoryExamInfo?.proctoring?.errors?.length === 0 && <Typography fontSize={'14px'}>
                            Нарушений не выявлено
                        </Typography>}
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'row'}
                >
                    {theoryExamInfo?.proctoring?.file_url && <a href={theoryExamInfo?.proctoring?.file_url}><Chip label={'Скачать видеозапись прохождения экзамена'} sx={{cursor: 'pointer'}}/></a>}
                </Stack>
            </Stack>
        </Box>
    );
};

export default TheoryExamResultsPage;
