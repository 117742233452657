export const zero = (value: string | number) => ('00' + String(value)).slice(-2);

export const formatDate = (value: any | null | undefined, fallback: string = '—') => {
    if (!value) return fallback;

    const date = new Date(value);
    // @ts-ignore
    if (!date || isNaN(date)) return fallback;

    return `${zero(date.getDate())}.${zero(date.getMonth() + 1)}.${date.getFullYear()}`;
};

interface NameProps {
    first_name?: string | null,
    last_name?: string | null,
    patronymic?: string | null
}

interface NameResult {
    firstName: string,
    lastName: string,
    patronymic: string,
    fullName: string,
    partialName: string
}

export const getName = (props: NameProps): NameResult => {
    const firstName = (props.first_name || '').trim().replace(/\s+/g, ' ');
    const lastName = (props.last_name || '').trim().replace(/\s+/g, ' ');
    const patronymic = (props.patronymic || '').trim().replace(/\s+/g, ' ');
    return {
        firstName, lastName, patronymic,
        fullName: [lastName, firstName, patronymic].join(' ').trim().replace(/\s+/g, ' '),
        partialName: `${lastName[0]}${firstName[0]}`
    };
};

function maskString(input: string): string {
    if (input.length <= 1) {
        return input;
    }

    const firstTwoChars = input.substring(0, 1);
    const maskedPart = '*'.repeat(input.length - 1);

    return firstTwoChars + maskedPart;
}

export const maskName = (props: NameProps) => {
    const firstName = (props.first_name || '').trim().replace(/\s+/g, ' ');
    const lastName = (props.last_name || '').trim().replace(/\s+/g, ' ');
    const patronymic = (props.patronymic || '').trim().replace(/\s+/g, ' ');

    const maskedFirstName = maskString(firstName);
    const maskedLastName = maskString(lastName);
    const maskedPatronymic = maskString(patronymic);

    return [maskedLastName, maskedFirstName, maskedPatronymic].join(' ').trim().replace(/\s+/g, ' ')
};

export const formatPhone = (value: any | null | undefined, fallback: string = '—') => {
    if (!value || !value.length) return fallback;

    const number = value.replace(/[^0-9]/gmi, '');
    const phone = number.length === 11 ? '7' + value.slice(1) : number;

    if (!phone || !phone.length) return fallback;

    if (phone.length === 11) {
        return `+7 (${phone.slice(1, 4)}) ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 12)}`;
    } else {
        return phone;
    }
};

export const formatDateTime = (value: any | null | undefined, fallback: string = '—') => {
    if (!value) return fallback;

    const date = new Date(value);
    // @ts-ignore
    if (!date || isNaN(date)) return fallback;

    return `${zero(date.getDate())}.${zero(date.getMonth() + 1)}.${date.getFullYear()} ${zero(date.getHours())}:${zero(date.getMinutes())}`;
};

export const formatDateTimeSeconds = (value: any | null | undefined, fallback: string = '—') => {
    if (!value) return fallback;

    const date = new Date(value);
    if (isNaN(date.getTime())) return fallback;

    // Добавляем 5 часов к исходному времени
    const offsetDate = new Date(date.getTime() + 5 * 60 * 60 * 1000);

    const zero = (num: number) => (num < 10 ? `0${num}` : num);

    return `${zero(offsetDate.getDate())}.${zero(offsetDate.getMonth() + 1)}.${offsetDate.getFullYear()} ${zero(offsetDate.getHours())}:${zero(offsetDate.getMinutes())}:${zero(offsetDate.getSeconds())}`;
};

export const convertToStandardDate = (dateString: string) => {
    const [day, month, year] = dateString.split(".");
    return new Date(`${year}-${month}-${day}`);
}

export const convertFromDateTimeToDate = (dateString: string | Date | null) => {

    if (dateString === null || dateString === '') return '';

    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
}



export const stripTimeFromDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
}